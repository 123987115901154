/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import ControlledBrandAutocomplete from "@utils/forms/ControlledBrandAutocomplete";

import {
  useItemTypes,
  useOrderCalendarMonthOptions,
  useProgramInfo,
} from "../../../hooks/planningToolHooks";
import {
  cloneCouponOffer,
  createItem,
} from "../../../redux/slices/planningTool/itemProgramSlice";
import { SelectInput } from "../../Forms/ControlledInputs";
import { StyledButton } from "../../StyledComponents";
import ScrollIntoView from "../../Utility/ScrollIntoView";
import CouponForm from "./CouponForm";
import FormItemTypeSelector from "./FormItemTypeSelector";
import NewWrapper from "./NewWrapper";
import {
  applyDefaultsFromItemType,
  couponClonableFields,
  couponDataFromForm,
  fields,
  formDataFromCoupon,
} from "./helpers";

const CouponNew = ({ defaultValues }) => {
  const dispatch = useDispatch();
  const {
    brandAssignmentLevel,
    isSubmitted,
    orderCalendarMonthId,
    orderType: programOrderType,
    programId,
    requiresOCM,
  } = useProgramInfo();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const couponItemTypeOptions = useItemTypes(
    (type) => type.categoryCode === "COU"
  );

  const { supplierId } = useSelector((state) => state.user);

  const ocms = useOrderCalendarMonthOptions();

  const programOcmInOptions = ocms.find(
    ({ id }) => id === orderCalendarMonthId
  );
  const isProgramOcmInOptions = !!programOcmInOptions;

  const formAttrs = useForm({
    mode: "onSubmit",
    defaultValues: formDataFromCoupon({}),
  });

  const { control, handleSubmit, reset, watch } = formAttrs;
  const clonedFromItemProgramId = watch("clonedFromItemProgramId");

  const requiredFields = fields.default.required.concat(
    isSubmitted ? fields.submitted.required : []
  );

  const disabledFields = clonedFromItemProgramId ? couponClonableFields : [];

  const controlled = (name, rules) => ({
    name,
    control,
    rules: { ...rules, required: requiredFields.includes(name) },
    disabled: disabledFields.includes(name),
  });

  const onSubmit = handleSubmit((_data) => {
    let data = couponDataFromForm(_data, programOrderType);
    setLoading(true);
    if (data.clonedFromItemProgramId) {
      dispatch(
        cloneCouponOffer(data.clonedFromItemProgramId, data, () =>
          setOpen(false)
        )
      );
    } else {
      if (!data.itemSubTypeId) {
        data = applyDefaultsFromItemType(data, couponItemTypeOptions);
      }
      dispatch(
        createItem(programId, data, () => {
          setOpen(false);
        })
      );
    }
  });

  useEffect(() => {
    if (Object.keys(defaultValues).length > 0) {
      reset(formDataFromCoupon(defaultValues));
      setOpen(true);
    }
  }, [defaultValues, reset]);

  // Set default values
  useEffect(() => {
    if (!open) {
      setLoading(false);
      reset(
        formDataFromCoupon({
          orderCalendarMonthId: isProgramOcmInOptions
            ? orderCalendarMonthId
            : "",
          supplierId,
        })
      );
    }
  }, [open, orderCalendarMonthId, reset, supplierId, isProgramOcmInOptions]);

  return (
    <NewWrapper name="Coupon" open={open} setOpen={setOpen}>
      {Object.keys(defaultValues).length > 0 && (
        <ScrollIntoView trigger={defaultValues} />
      )}
      {!isSubmitted && (
        <div tw="flex justify-between items-center">
          <div tw="flex-grow flex space-x-4">
            <FormItemTypeSelector
              tw="flex-grow max-w-md"
              label="Item Type"
              {...controlled("itemTypeId", { required: true })}
            />
            {brandAssignmentLevel === "item" && (
              <ControlledBrandAutocomplete
                tw="flex-grow max-w-xs"
                limitTags={1}
                multiple
                label="Brands"
                {...controlled("brands", { required: true })}
              />
            )}
            {requiresOCM && (
              <SelectInput
                tw="flex-grow max-w-xs"
                label="Order Window Month"
                options={ocms}
                {...controlled("orderCalendarMonthId", { required: true })}
              />
            )}
          </div>
          <StyledButton cta tw="ml-4" onClick={onSubmit} disabled={loading}>
            Create Coupon
          </StyledButton>
        </div>
      )}
      {isSubmitted && (
        <>
          <CouponForm
            form={formAttrs}
            controlled={controlled}
            clonedFromItemProgramId={clonedFromItemProgramId}
          />
          <br />
          <StyledButton cta onClick={onSubmit} disabled={loading}>
            Submit Coupon
          </StyledButton>
        </>
      )}
    </NewWrapper>
  );
};

export default CouponNew;
