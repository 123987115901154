/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";

import { DefaultTextField } from "src/components/Forms/DefaultInputs";
import { FixedHeightScrollLastChild } from "src/components/StyledComponents";

import { ItemSubType, ItemType } from "@models/ItemType";

import { useItemTypesQuery } from "../data";
import ItemSubTypeModal from "./ItemSubTypeModal";
import ItemTypeModal from "./ItemTypeModal";
import ItemTypeTable from "./ItemTypeTable";

const ItemTypesAdmin = () => {
  const [search, setSearch] = useState("");
  const [selectedItemType, setSelectedItemType] = useState<ItemType | null>(
    null
  );
  const [selectedItemSubType, setSelectedItemSubType] = useState<{
    itemSubType: ItemSubType | null;
    itemTypeId: string;
  } | null>(null);
  const { data = [], isLoading } = useItemTypesQuery();

  const filteredItemTypes = data.filter((it) => {
    const searchTerm = search.toLowerCase();
    return (
      it.description?.toLowerCase().includes(searchTerm) ||
      it.externalId?.toLowerCase().includes(searchTerm) ||
      it.categoryCode?.toLowerCase().includes(searchTerm)
    );
  });

  const handleSubTypeClick = (
    itemSubType: ItemSubType | null,
    itemTypeId: string
  ) => {
    setSelectedItemSubType({ itemSubType, itemTypeId });
  };

  return (
    <FixedHeightScrollLastChild tw="[--space-after-header: 60px] space-y-6">
      <h1 tw="font-medium text-2xl mb-2">Item Types</h1>
      <DefaultTextField
        placeholder="Search"
        tw="max-w-lg"
        autoFocus
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <ItemTypeTable
        data={filteredItemTypes}
        isLoading={isLoading}
        onItemTypeClick={(it) => setSelectedItemType(it)}
        onSubTypeClick={handleSubTypeClick}
      />
      {selectedItemType && (
        <ItemTypeModal
          handleClose={() => setSelectedItemType(null)}
          itemType={selectedItemType}
        />
      )}
      {selectedItemSubType && (
        <ItemSubTypeModal
          handleClose={() => setSelectedItemSubType(null)}
          itemSubType={selectedItemSubType.itemSubType}
          itemTypeId={selectedItemSubType.itemTypeId}
        />
      )}
    </FixedHeightScrollLastChild>
  );
};

export default ItemTypesAdmin;
